import { extendTheme, ThemeConfig } from '@chakra-ui/react';
const config: ThemeConfig = {
  initialColorMode: 'dark',
  useSystemColorMode: true,
};

const compeoTheme = extendTheme({
  config,

  fonts: {
    heading: 'Inter',
    body: 'Inter',
  },

  colors: {
    purple: {
      //  Because Janus was not initially designed with general variants such as "primary", "secondary", etc., the purple acts as the "primary".
      50: '#FEF0DF',
      100: '#FCD2A0',
      200: '#FAB461',
      300: '#F9A542',
      400: '#F89623',
      500: '#F87C22',
      600: '#aa6316',
      700: '#79470f',
      800: '#482b08',
      900: '#190e01',
    },
    blue: {
      50: '#e4fafb',
      100: '#c4eaed',
      200: '#a1dae1',
      300: '#7dccd4',
      400: '#5cbec8',
      500: '#45a5af',
      600: '#358088',
      700: '#245b61',
      800: '#12373b',
      900: '#001316',
    },
  },
  components: {
    Button: {
      defaultProps: {
        colorScheme: 'purple',
        type: 'submit',
      },
    },

    Heading: {
      baseStyle: {
        fontSize: '3xl',
        fontWeight: 'bold',
        color: 'purple.500',
      },
    },
  },
});

export default compeoTheme;
