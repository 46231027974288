import NProgress from 'nprogress';
import { GlobalEvent } from '@inertiajs/core/types/types';
import useGlobalStore from '@php-beam/packages/frontend/src/store/useGlobalStore';

let timeout: NodeJS.Timeout | null = null;

function addEventListeners(delay: number): void {
  document.addEventListener('inertia:start', start.bind(null, delay));
  document.addEventListener('inertia:finish', finish);
}

function start(delay: number): void {
  useGlobalStore.getState().setIsLoading(true);
  timeout = setTimeout(() => NProgress.start(), delay);
}

function finish(event: GlobalEvent<'finish'>) {
  clearTimeout(timeout!);
  useGlobalStore.getState().setIsLoading(false);
  if (!NProgress.isStarted()) {
    return;
  } else if (event.detail.visit.completed) {
    NProgress.done();
  } else if (event.detail.visit.interrupted) {
    NProgress.set(0);
  } else if (event.detail.visit.cancelled) {
    NProgress.done();
    NProgress.remove();
  }
}

function injectCSS(color: string) {
  const element = document.createElement('style');
  element.type = 'text/css';
  element.textContent = `
    #nprogress, .nprogress-busy {
      pointer-events: none;
    }

    #nprogress .backdrop {
      pointer-events: none;
      background: ${color};
      content: '';
      opacity: 0.2;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    
    #nprogress .bar {
      display: none;
    }

    #nprogress .spinner {
      display: block;
      position: fixed;
      z-index: 2;
      top: 15px;
      right: 15px;
    }

    #nprogress .spinner-icon {
      width: 24px;
      height: 24px;
      box-sizing: border-box;

      border: solid 2px transparent;
      border-top-color: ${color};
      border-left-color: ${color};
      border-radius: 50%;

      -webkit-animation: nprogress-spinner 400ms linear infinite;
              animation: nprogress-spinner 400ms linear infinite;
    }

    .nprogress-custom-parent {
      overflow: hidden;
      position: relative;
    }

    .nprogress-custom-parent #nprogress .spinner,
    .nprogress-custom-parent #nprogress .bar {
      position: absolute;
    }

    @-webkit-keyframes nprogress-spinner {
      0%   { -webkit-transform: rotate(0deg); }
      100% { -webkit-transform: rotate(360deg); }
    }
    @keyframes nprogress-spinner {
      0%   { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;
  document.head.appendChild(element);
}

const Progress = {
  init({
    delay = 250,
    color = 'var(--chakra-colors-purple-500)',
    includeCSS = true,
    showSpinner = false,
  } = {}) {
    addEventListeners(delay);
    NProgress.configure({
      showSpinner,
      trickle: false,
      template: `<div class="backdrop"></div><div class="bar" role="bar"></div><div class="spinner" role="spinner"><div class="spinner-icon"></div></div>`,
    });
    if (includeCSS) {
      injectCSS(color);
    }
  },
};

export default Progress;
