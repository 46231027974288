import React, { useMemo } from 'react';
import { FCWithChildren } from '@php-beam/packages/frontend/src/types/global';
import CompeoDarkLogo from '@php-beam/packages/frontend/assets/logos/compeo/Dark Mode Compeo.png';
import CompeoLightLogo from '@php-beam/packages/frontend/assets/logos/compeo/Light mode Compeo.png';
import JanusDarkIcon from '@php-beam/packages/frontend/assets/logos/Purple Green Icon.png';
import JanusDarkLogo from '@php-beam/packages/frontend/assets/logos/Dark Mode Green.png';
import compeoTheme from '@php-beam/packages/frontend/src/contexts/themes/compeo';
import janusTheme from '@php-beam/packages/frontend/src/contexts/themes/janus';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import useJanusThemeStore, {
  JanusThemeStoreI,
} from '@php-beam/packages/frontend/src/store/useJanusThemeStore';

export type LogoType = {
  icon: string;
  logo: string;
};

export type ThemeConfigType = {
  logos: {
    _dark: LogoType;
    _light: LogoType;
  };
  title?: string;
  loginLegend?: string;
  chakraTheme: Record<string, any>;
};

export type JanusThemeContextType = {
  theme?: ThemeType;
  selectedTheme: ThemeConfigType;
  themes: ThemesType;
  setTheme: JanusThemeStoreI['setTheme'];
};

export type ThemesType = { [key in ThemeType]: ThemeConfigType };

export const JanusThemeContext = React.createContext<JanusThemeContextType>(
  {} as JanusThemeContextType,
);

export type ThemeType = 'compeo' | 'janus';

export type JanusThemeContextProviderProps = Pick<
  JanusThemeContextType,
  'theme'
>;

const themes: ThemesType = {
  compeo: {
    logos: {
      _dark: { icon: CompeoDarkLogo, logo: CompeoDarkLogo },
      _light: { icon: CompeoLightLogo, logo: CompeoLightLogo },
    },
    loginLegend: 'Welcome to Broker Portal!',
    chakraTheme: compeoTheme,
    title: 'Compeo',
  },
  janus: {
    logos: {
      _dark: { icon: JanusDarkIcon, logo: JanusDarkLogo },
      _light: { icon: JanusDarkIcon, logo: JanusDarkLogo },
    },
    loginLegend: 'Welcome back, happy to see you!',
    chakraTheme: janusTheme,
  },
};

const JanusThemeContextProvider: FCWithChildren<
  JanusThemeContextProviderProps
> = ({ children, ...props }) => {
  const { theme: themeFromStore, setTheme } = useJanusThemeStore();

  const values = useMemo(() => {
    const theme = props.theme ?? themeFromStore ?? 'janus';
    return {
      ...props,
      theme,
      selectedTheme: themes[theme as ThemeType] ?? themes.janus,
      themes,
      setTheme,
    };
  }, [props, themeFromStore, setTheme]);

  return (
    <>
      <ColorModeScript
        initialColorMode={
          values.selectedTheme.chakraTheme.config.initialColorMode
        }
      />

      <JanusThemeContext.Provider value={values}>
        <ChakraProvider theme={values.selectedTheme.chakraTheme}>
          {children}
        </ChakraProvider>
      </JanusThemeContext.Provider>
    </>
  );
};

export default JanusThemeContextProvider;
