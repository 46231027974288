import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { ThemeType } from '@php-beam/packages/frontend/src/contexts/JanusThemeContextProvider';

export interface JanusThemeStoreI {
  theme: ThemeType | null;
  setTheme: (theme: ThemeType) => void;
}

const useJanusThemeStore = create<JanusThemeStoreI>()(
  persist(
    (set) => ({
      theme: null,
      setTheme: (theme: ThemeType) =>
        set((state) => ({
          ...state,
          theme,
        })),
    }),
    {
      name: 'janus-theme-store',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);

export default useJanusThemeStore;
