export const {
  VITE_APP_NAME,
  VITE_FACEBOOK_APP_ID,
  VITE_FACEBOOK_APP_DEFAULT_GRAPH_VERSION,
  VITE_DIALPAD_API_KEY,
  VITE_PUSHER_APP_KEY,
  VITE_PUSHER_APP_CLUSTER,
  VITE_SENTRY_DSN,
  VITE_FULLSTORY_ORG,
  VITE_TINYMCE_API_KEY,
} = import.meta.env;
