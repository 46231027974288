import { extendTheme, ThemeConfig } from '@chakra-ui/react';
const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: true,
};

const janusTheme = extendTheme({
  config,

  fonts: {
    heading: 'Inter',
    body: 'Inter',
  },

  components: {
    Button: {
      defaultProps: {
        colorScheme: 'purple',
        type: 'submit',
      },
    },

    Heading: {
      baseStyle: {
        fontSize: '3xl',
        fontWeight: 'bold',
        color: 'purple.500',
      },
    },
  },
});

export default janusTheme;
