import React, { useEffect, useState } from 'react';
import { PusherEventData } from '@php-beam/packages/frontend/src/types/shared';
import { Box, HStack, Link, Spacer, Text } from '@chakra-ui/react';
import { AiOutlineInfoCircle } from 'react-icons/all';

type Context = {
  pusherNotification: PusherEventData;
  setPusherNotification: (data: PusherEventData) => void;
};
type PusherClientProps = { children: React.ReactNode };
export const PusherContext = React.createContext<Context>({
  pusherNotification: undefined,
  setPusherNotification: (pusherEventData: PusherEventData) => {},
});

export const customToast = (name: string, message: string, url?: string) => {
  return (
    <>
      <Link href={url ?? undefined}>
        <Box color="white" p={3} bg="purple.500" rounded={'xl'} m={5}>
          <HStack pr={3}>
            <Text>{name}</Text>
            <Spacer />
            <AiOutlineInfoCircle size={'20'} />
          </HStack>
          <Text>{message}</Text>
        </Box>
      </Link>
    </>
  );
};
export const PusherNotificationClientProvider = ({
  children,
}: PusherClientProps) => {
  const [pusherNotification, setPusherNotification] =
    useState<PusherEventData>(undefined);

  return (
    <PusherContext.Provider
      value={{ pusherNotification, setPusherNotification }}
    >
      {children}
    </PusherContext.Provider>
  );
};
